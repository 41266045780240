<template>
    <div style="margin-top: 5px;">
        <div class="filter-line2">
            <van-dropdown-menu>
                <van-dropdown-item v-model="filter.equipModel" :title="filter.equipModel || '设备类型'" :options="filter.equipModelOpts" @change="filterData"></van-dropdown-item>
                <van-dropdown-item v-model="filter.debugStatus" :title="filter.debugStatus? null : '调试状态'" :options="filter.debugStatusOpts" @change="filterData"></van-dropdown-item>
                <van-dropdown-item v-model="filter.otherCond" :title="filter.otherCond || '更多条件'" :options="filter.otherCondOpts" @change="filterData"></van-dropdown-item>
            </van-dropdown-menu>
        </div>
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
            <device-info v-for="(item, i) in list" :item="item" :key="i" @click.native="toDeviceInfo(item)"></device-info>
        </van-list>        
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {formatDate} from '@/utils/common'
import deviceInfo from '@/components/DeviceInfo'
import {getEquipModel, getEquipList} from '@/axios/device'
export default {
    components: {deviceInfo},
    computed: {
        ...mapGetters(['equipInfo'])
    },
    data(){
        return {
            loading: false,
            finished: false,
            filter: {
                equipModel: null,
                debugStatus: null,
                otherCond: null,
                equipModelOpts: [{text: '全部', value: null}],
                debugStatusOpts: [{text: '全部', value: null},{text: '等待调试', value: 'waiting_num'},
                {text: '正在调试', value: 'debuging_num'},{text: '完成调试', value: 'debuged_num'}],
                otherCondOpts: [{text: '无', value: null}]
            },
            list: [],
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0
            }
        }
    },
    created(){
        getEquipModel({
            equipType: this.equipInfo.equipType
        }).then(res=>{
            this.filter.equipModelOpts = this.filter.equipModelOpts.concat(res.content || [])
        })
        this.getEquipList()
    },
    methods: {
        onLoad(){
            this.page.pageNo++
            this.getEquipList()
        },
        filterData(){
            this.getEquipList(true)
        },
        getEquipList(_reset){
            if(_reset){
                this.page.pageNo = 1
                this.page.total = 0
                this.list = []
            }
            getEquipList({
                companyId: this.equipInfo.companyId,
                equipType: this.equipInfo.equipType,
                pageNo: this.page.pageNo,
                pageSize: this.page.pageSize,
                type: 3, // 类型1月 2日 3自定义
                //date: formatDate(new Date()),
                endTime: formatDate(new Date()),
                startTime: formatDate(new Date(new Date() - 30 * 24 * 3600 * 1000)),
                series: this.filter.equipModel,
                debugState: this.filter.debugStatus
            }).then(res => {
                this.list = this.list.concat(res.content.list || [])
                this.page.total = res.content.totalRecords
                this.finished = this.list.length >= this.page.total
            }).finally(()=>{                
                this.loading = false
            })
        },
        toDeviceInfo(info){
            this.$router.push({path: `/home/deviceDetail/${info.equipCode}`})
        }
    }    
}
</script>

<style lang="scss" scoped>

</style>